import React, { useEffect, useState } from "react";
import { Button, Drawer, Flex, Input, Modal, Rate, Tabs, Tag, message } from "antd";
import { Row, Col, Card } from "antd";
import { CalendarOutlined, CloseOutlined } from "@ant-design/icons";
import Images from "../assets/image";
import IResponse from "../types/response";
import { useGetTripDetail, useFetchUserLocation, useTripCancel, useTripDelete, useTripScheduleAgentReject, useFetchTripCancellationCharge, useUpdatePaymentRefund } from "../services/trip.service";
import { useGetTravellerTripPaymentHistory } from "../services/traveller.service";
import { formatDateTime, formatDateWithOrdinalSuffix, isNotNullUndefined, isNotNullUndefinedBlank } from "../helpers/common";
import { ICities, ITripItineraryDetail, ITripPaymentHistory, ITripTravellerInfo, IUserLocation } from "../types/trip";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { storage } from "../utils/storage";
import { useNavigate, useParams } from "react-router-dom";

const TripDetails: React.FC = () => {
    const { id: tripId } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [tripTravellerInfo, setTripTravellerInfo] = useState<ITripTravellerInfo>();
    const [tripItineraryDetail, setTripItineraryDetail] = useState<ITripItineraryDetail[]>([]);
    const [tripItineraryAgentDetail, setTripItineraryAgentDetail] = useState<ITripItineraryDetail[]>([]);
    const [tripItineraryCities, setTripItineraryCities] = useState<ICities[]>([]);
    const [tripPaymentHistory, setTripPaymentHistory] = useState<ITripPaymentHistory[]>([]);
    const [tripFromDate, setTripFromDate] = useState<string>("");
    const [tripTodate, setTripToDate] = useState<string>("");
    const [tripCityFromDate, setTripCityFromDate] = useState<string>("");
    //const [tripCityToDate, setTripCityToDate] = useState<string>("");
    const [agentTotalServiceHours, setAgentServiceHours] = useState<number>(0);
    const [agentChargeAmount, setChargeAmount] = useState<string>("");
    //const [agentTotalChargeAmount, setTotalChargeAmount] = useState<string>("");
    //const [agentTotalTaxAmount, setTotalTaxAmount] = useState<string>("");
    const [agentTotalCommissionAmount, setAgentTotalCommissionAmount] = useState<string>("");
    const [arrivalDateTime, setArrivalDateTime] = useState<string>("");
    const [scheduleStartDateTime, setScheduleStartDateTime] = useState('');
    const [scheduleEndDateTime, setscheduleEndDateTime] = useState('');

    const [open, setOpen] = useState(false);
    const [userLocations, setUserLocations] = useState<IUserLocation[]>([]);
    const [lastUpdatedTraveller, setLastUpdatedTraveller] = useState('');
    const [lastUpdatedAgent, setLastUpdatedAgent] = useState('');
    const [travellerCenter, setTravellerCenter] = useState({ lat: 0, lng: 0 });
    const [agentCenter, setAgentCenter] = useState({ lat: 0, lng: 0 });
    const [travellerStartLocation, setTravellerStartLocation] = useState({ lat: 0, lng: 0 });
    const [travellerEndLocation, setTravellerEndLocation] = useState({ lat: 0, lng: 0 });
    const [agentStartLocation, setAgentStartLocation] = useState({ lat: 0, lng: 0 });
    const [agentEndLocation, setAgentEndLocation] = useState({ lat: 0, lng: 0 });
    const { TabPane } = Tabs;
    const [modalType, setModalType] = useState<'reject' | 'delete' | 'cancel' | ''>('');
    const [reason, setReason] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [, setCancellationOption] = useState<'withCharges' | 'withoutCharges' | null>(null);
    const [isPaymentRefundable, setPaymentRefundable] = useState(false);
    const [isReasonRequired, setIsReasonRequired] = useState(false);

    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    useEffect(() => {
        let token = storage.getToken() ?? "";
        if (!isNotNullUndefinedBlank(token)) {
            navigate("/");
            return;
        }
        getTripDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tripId]);

    const getTripDetail = async () => {
        getTripDetailMutation.mutate({ tripId: tripId });
    };

    const getTripDetailMutation = useGetTripDetail({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200 && isNotNullUndefined(response.data)) {
                if (isNotNullUndefined(response.data.tripTravellerInfo)) {
                    setTripTravellerInfo(response.data.tripTravellerInfo);
                }
                if (isNotNullUndefined(response.data.tripItineraryDetail) && response.data.tripItineraryDetail.length > 0) {
                    // response.data.tripItineraryDetail.forEach((x: { taxAmount: number; chargeAmount: string; }) => { x.taxAmount = (parseFloat(x.chargeAmount) / 100) * parseFloat(response.data.tripTravellerInfo.taxPercentage) });
                    setTripItineraryDetail(response.data.tripItineraryDetail);

                    let cities = response.data.tripItineraryDetail.reduce((distinctCities: { referenceId: any, cityId: any; cityName: any; }[], item: { referenceId: any, cityId: any; cityName: any; }) => {
                        if (!distinctCities.some(x => x.referenceId === item.referenceId)) {
                            distinctCities.push({ referenceId: item.referenceId, cityId: item.cityId, cityName: item.cityName });
                        }
                        return distinctCities;
                    }, []);
                    setTripItineraryCities(cities);
                    const uniqueDates = response.data.tripItineraryDetail.map((item: { scheduleDate: string | number | Date; }) => new Date(item.scheduleDate));

                    setTripFromDate(formatDateWithOrdinalSuffix(new Date(Math.min(...uniqueDates))));
                    setTripToDate(formatDateWithOrdinalSuffix(new Date(Math.max(...uniqueDates))));
                    const agents = response.data.tripItineraryDetail.filter((x: { referenceId: any; }) => x.referenceId === cities[0].referenceId);
                    const arrivalDate = formatDateTime(new Date(agents[0].scheduleDate), "DD/MM/YYYY");
                    const arrivalTime = formatDateTime(agents[0]?.fromTime, "HH:mm");
                    setArrivalDateTime(`${arrivalDate} ${arrivalTime}`);

                    setTripItineraryAgentDetail(agents);

                    const cityUniqueDates = agents.map((item: { scheduleDate: Date; }) => new Date(item.scheduleDate));
                    setTripCityFromDate(formatDateWithOrdinalSuffix(new Date(Math.min(...cityUniqueDates)), true));
                    // setTripCityToDate(formatDateWithOrdinalSuffix(new Date(Math.max(...cityUniqueDates)), true));

                    const totalServiceHours = agents.reduce((sum: any, agent: { actualHours: any; }) => sum + agent.actualHours, 0);
                    setAgentServiceHours(totalServiceHours);

                    const totalChargeAmount = agents.reduce((sum: any, agent: { chargeAmount: any; }) => sum + agent.chargeAmount, 0);
                    setChargeAmount(totalChargeAmount);

                    // const totalTaxAmount = agents.reduce((sum: any, agent: { taxAmount: any; }) => sum + agent.taxAmount, 0);
                    // setTotalTaxAmount(totalTaxAmount);

                    // const totalAmount = (parseFloat(totalChargeAmount) + parseFloat(totalTaxAmount));
                    // setTotalChargeAmount(totalAmount.toString());

                    const totalAgentCommissionAmount = agents.reduce((sum: any, agent: { agentCommissionAmount: any; }) => sum + agent.agentCommissionAmount, 0);
                    setAgentTotalCommissionAmount(totalAgentCommissionAmount);

                    const scheduleStartedAtTimestamp = response.data.tripItineraryDetail[0].scheduleStartedAt;
                    const scheduleEndedAtTimestamp = response.data.tripItineraryDetail[0].scheduleEndedAt;

                    const scheduleStartDateTime = new Date(scheduleStartedAtTimestamp);
                    const scheduleStartedAt = formatDateTime(scheduleStartDateTime, "HH:mm AMPM");
                    setScheduleStartDateTime(scheduleStartedAt);

                    const scheduleEndDateTime = new Date(scheduleEndedAtTimestamp);
                    const scheduleEndedAt = formatDateTime(scheduleEndDateTime, "HH:mm AMPM");
                    setscheduleEndDateTime(scheduleEndedAt);
                    fetchUserLocations(response.data.tripItineraryDetail[0].scheduleId, response.data.tripTravellerInfo.travellerId, agents[0].agentId);
                }
            } else {
                message.error(response.statusMessage);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        }
    });

    const onChangeCityTab = (key: string) => {
        const agents = tripItineraryDetail.filter(x => x.referenceId === key);
        const tripSchedule = tripItineraryDetail.find(x => x.tripStatus !== 'Cancelled' && x.tripStatus !== 'Deleted');
        if (tripSchedule !== undefined && tripSchedule !== null) {
            const arrivalDate = formatDateTime(new Date(agents[0].scheduleDate), "DD/MM/YYYY");
            const arrivalTime = formatDateTime(agents[0]?.fromTime, "HH:mm");
            setArrivalDateTime(`${arrivalDate} ${arrivalTime}`);
            setTripItineraryAgentDetail(agents);
        }
        else {
            const arrivalDate = formatDateTime(new Date(tripItineraryDetail[0].scheduleDate), "DD/MM/YYYY");
            const arrivalTime = formatDateTime(agents[0]?.fromTime, "HH:mm");
            setArrivalDateTime(`${arrivalDate} ${arrivalTime}`);
            setTripItineraryAgentDetail(agents);
        }

        const uniqueDates = agents.map((item: { scheduleDate: Date; }) => new Date(item.scheduleDate));
        setTripCityFromDate(formatDateWithOrdinalSuffix(new Date(Math.min(...uniqueDates.map(date => date.getTime()))), true));
        // setTripCityToDate(formatDateWithOrdinalSuffix(new Date(Math.max(...uniqueDates.map(date => date.getTime()))), true));

        const totalServiceHours = agents.reduce((sum: any, agent: { actualHours: any; }) => sum + agent.actualHours, 0);
        setAgentServiceHours(totalServiceHours);

        const totalChargeAmount = agents.reduce((sum: any, agent: { chargeAmount: any; }) => sum + agent.chargeAmount, 0);
        setChargeAmount(totalChargeAmount);

        // const totalTaxAmount = agents.reduce((sum: any, agent: { taxAmount: any; }) => sum + agent.taxAmount, 0);
        // setTotalTaxAmount(totalTaxAmount);

        const totalAgentCommissionAmount = agents.reduce((sum: any, agent: { agentCommissionAmount: any; }) => sum + agent.agentCommissionAmount, 0);
        setAgentTotalCommissionAmount(totalAgentCommissionAmount);

        // const totalAmount = (parseFloat(totalChargeAmount) - parseFloat(totalTaxAmount)) + parseInt(tripTravellerInfo?.platformFee ?? "");
        // setTotalChargeAmount(totalAmount.toString());

        fetchUserLocations(tripSchedule?.scheduleId ?? "", tripTravellerInfo?.travellerId ?? "", agents[0].agentId)
    };

    const fetchUserLocations = async (scheduleId: string, travellerId: string, agentId: string) => {
        getUserLocationMutation.mutate({ scheduleId, userIds: travellerId + ',' + agentId });
    };

    const getUserLocationMutation = useFetchUserLocation({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                setUserLocations(response.data);
                const travellerData = response.data.find((user: any) => user.userId === tripTravellerInfo?.travellerId);
                const agentData = response.data.find((user: any) => user.userId === tripItineraryAgentDetail[0].agentId);

                if (travellerData) {
                    const travellerLocation = travellerData?.location;
                    const travellerTripStartLocation = travellerData?.tripStartLocation;
                    const travellerTripEndLocation = travellerData?.tripEndLocation;
                    const travellerDateTime = travellerData?.dateTime;

                    if (isNotNullUndefined(travellerTripStartLocation)) {
                        setTravellerStartLocation({
                            lat: travellerTripStartLocation.latitude,
                            lng: travellerTripStartLocation.longitude,
                        });
                    }

                    if (isNotNullUndefined(travellerTripEndLocation)) {
                        setTravellerEndLocation({
                            lat: travellerTripEndLocation.latitude,
                            lng: travellerTripEndLocation.longitude,
                        });
                    }

                    if (isNotNullUndefined(travellerLocation)) {
                        setTravellerCenter({
                            lat: travellerLocation.latitude,
                            lng: travellerLocation.longitude,
                        });
                        setLastUpdatedTraveller(formatDateTime(travellerDateTime, "DD:MM:YYYY HH:mm AMPM"));
                    }
                }

                if (agentData) {
                    const agentLocation = agentData?.location;
                    const agentTripStartLocation = agentData?.tripStartLocation;
                    const agentTripEndLocation = agentData?.tripEndLocation;
                    const agentDateTime = agentData?.dateTime;

                    if (isNotNullUndefined(agentTripStartLocation)) {
                        setAgentStartLocation({
                            lat: agentTripStartLocation.latitude,
                            lng: agentTripStartLocation.longitude,
                        });
                    }

                    if (isNotNullUndefined(agentTripEndLocation)) {
                        setAgentEndLocation({
                            lat: agentTripEndLocation.latitude,
                            lng: agentTripEndLocation.longitude,
                        });
                    }

                    if (isNotNullUndefined(agentLocation)) {
                        setAgentCenter({
                            lat: agentLocation.latitude,
                            lng: agentLocation.longitude,
                        });
                        setLastUpdatedAgent(formatDateTime(agentDateTime, "DD:MM:YYYY HH:mm AMPM"));
                    }
                }
            } else {
                message.error('Error fetching user locations:');
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        }
    });

    const getPaymentHistory = async () => {
        const travellerId = tripTravellerInfo?.travellerId ?? "";
        const tripId = tripTravellerInfo?.tripId ?? "";
        getPaymentHistoryMutation.mutate({ travellerId, tripId });
    };

    const getPaymentHistoryMutation = useGetTravellerTripPaymentHistory({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                setTripPaymentHistory(response.data);
            }
            else {
                message.error('Error fetching trip payment history.');
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        }
    });

    const showDrawer = () => {
        getPaymentHistory();
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCancelTrip = async (tripId: string, travellerId: string, scheduleId: string, isPaymentRefundable: boolean, cancelledReason: string) => {
        cancelTripMutation.mutate({ tripId: tripId, travellerId: travellerId, scheduleId: scheduleId, isPaymentRefundable: isPaymentRefundable, cancelledReason: cancelledReason });
    };

    const cancelTripMutation = useTripCancel({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                message.success('Trip cancelled successfully.');
                getTripDetail();
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        }
    });

    const onDeleteTrip = async (tripId: string, scheduleId: string) => {
        deleteTripMutation.mutate({ tripId: tripId, scheduleId: scheduleId });
    };

    const deleteTripMutation = useTripDelete({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                message.success('Trip deleted successfully.');
                getTripDetail();
            }
            else {
                message.error('Error deleting trip.');
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        }
    });

    const onCancelAgent = async (tripId: string, scheduleId: string, agentId: string, referenceId: string, cancelledReason: string) => {
        cancelTripAgentMutation.mutate({ tripId: tripId, scheduleId: scheduleId, agentId: agentId, referenceId: referenceId, cancelledReason: cancelledReason });
    };

    const cancelTripAgentMutation = useTripScheduleAgentReject({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                message.success('Attendant cancelled successfully.');
                getTripDetail();
            }
            else {
                message.error('Error cancelling attendant.');
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        }
    });

    const getCancellationCharge = async (tripId: string, scheduleId?: string) => {
        getCancellationChargeMutation.mutate({ tripId: tripId, scheduleId: scheduleId });
    };

    const getCancellationChargeMutation = useFetchTripCancellationCharge(tripId, {
        onSuccess: (result: IResponse) => {
            if (result != null && result.data && result.data.isPaymentRefundable !== undefined) {
                setPaymentRefundable(result.data.isPaymentRefundable);
            } else {
                message.error("Unexpected response format:");
            }
        },
        onError: (error: any) => {
            alert(error.message);
        },
    });

    const updatePaymentRefund = async (tripId: string, scheduleId: string) => {
        updatePaymentRefundMutation.mutate({ tripId: tripId, scheduleId: scheduleId })
    }

    const updatePaymentRefundMutation = useUpdatePaymentRefund({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                getTripDetail();
            } else {
                message.error(response.statusMessage);
            }
        },
        onError: (error: any) => {
            alert(error.message);
        },
    });

    const onRejectAgent = async () => {
        setModalType('reject');
        setIsModalVisible(true);
    };

    const onTripCancel = async (tripId: string, scheduleId: string) => {
        setModalType('cancel');
        setIsModalVisible(true);
        try {
            await getCancellationCharge(tripId, scheduleId);
        } catch (error) {
            message.error('Error fetching cancellation charge. Please try again.');
        }
    };

    const onTripDelete = async (tripId: string, scheduleId: string) => {
        setModalType('delete');
        setIsModalVisible(true);
    };

    const getModalTitle = () => {
        let message = "";
        if (modalType === 'delete') {
            message = "Confirm Deletion!";
        }
        if (modalType === 'cancel') {
            message = "Confirm Cancellation!";
        }
        else if (modalType === 'reject') {
            message = "Confirm Rejection!";
        }
        return message;
    };

    const getModelContent = () => {
        let modalContent = <></>;
        if (modalType === 'delete') {
            modalContent = (
                <>
                    <p className="mb-4">
                        Are you sure you want to delete <b>{tripTravellerInfo?.fullName ?? ""}</b> Trip's Delete request? Once deleted, this trip will not be recovered. <b>Do you wish to proceed?</b>
                    </p>
                </>
            );
        }
        else if (modalType === 'cancel') {
            modalContent = (
                <>
                    <p className="mb-4">
                        Please provide a reason for canceling this trip, and choose whether you want to charge the traveler for cancellation fees based on the service hours or not.
                    </p>
                    <b className="block mb-2">Reason for Cancellation:</b>
                    <Input.TextArea
                        rows={4}
                        value={reason}
                        onChange={(e) => {
                            setReason(e.target.value);
                            if (e.target.value.trim()) setIsReasonRequired(false);
                        }}
                        placeholder="Enter reason for trip cancellation"
                        className={`border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 ${isReasonRequired ? 'border-red-500' : ''}`}
                    />
                    {isReasonRequired && (
                        <p className="ant-form-item-explain-error text-red-500 mt-2 font-semibold">
                            Please input your Cancellation reason!
                        </p>
                    )}
                    {/* {isPaymentRefundable !== null && (
                        <p className="text-black-500 mt-4">
                            <b>Cancellation Charge:</b> ${isPaymentRefundable}
                        </p>
                    )} */}
                    <div className="flex justify-end space-x-2 mt-4">
                        {isPaymentRefundable === false ? (
                            <>
                                <Button className="bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black" onClick={handleCancelWithoutCharges}>
                                    Cancel without Charges
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button className="bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black" onClick={handleCancelWithCharges}>
                                    Cancel with Charges
                                </Button>
                                <Button type="default" onClick={handleCancelWithoutCharges}>
                                    Cancel without Charges
                                </Button>
                            </>
                        )}
                    </div>
                </>
            );
        }
        else if (modalType === 'reject') {
            modalContent = (
                <>
                    <p className="mb-4">
                        Are you sure you want to <b>reject</b> this Attendant's screening request? Please provide a reason for the rejection below. The attendant will be notified with the provided reason. <b>Do you wish to proceed?</b>
                    </p>
                    <b className="block mb-2">Reason for Rejection:</b>
                    <Input.TextArea
                        rows={4}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        placeholder="Enter reason for attendant rejection"
                        className="border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                    />
                </>
            );
        }
        return modalContent;
    };

    const handleCancelWithCharges = async () => {
        if (!reason.trim()) {
            setIsReasonRequired(true);
            return;
        }
        try {
            setIsReasonRequired(false);
            setCancellationOption('withCharges');
            handleSave();

        } catch (error) {
            message.error('Error fetching cancellation charge. Please try again.');
        }
    };

    const handleCancelWithoutCharges = async () => {
        if (!reason.trim()) {
            setIsReasonRequired(true);
            return;
        }
        setIsReasonRequired(false);
        setCancellationOption('withoutCharges');
        handleSave();
    };

    const handleSave = async () => {
        try {
            if (modalType === 'delete') {
                await onDeleteTrip(tripTravellerInfo?.tripId ?? "", tripItineraryAgentDetail[0]?.scheduleId ?? "")
            }
            else if (modalType === 'cancel') {
                if (!reason) {
                    message.error('Please provide a reason for cancelation.');
                    return;
                }
                if (reason.length > 300) {
                    message.error('The reason cannot exceed 300 characters.');
                    return;
                }
                await onCancelTrip(tripTravellerInfo?.tripId ?? "", tripTravellerInfo?.travellerId ?? "", tripItineraryAgentDetail[0]?.scheduleId ?? "", isPaymentRefundable, reason)
            }
            else if (modalType === 'reject') {
                if (!reason) {
                    message.error('Please provide a reason for rejection.');
                    return;
                }
                if (reason.length > 300) {
                    message.error('The reason cannot exceed 300 characters.');
                    return;
                }
                await onCancelAgent(tripTravellerInfo?.tripId ?? "", tripItineraryAgentDetail[0]?.scheduleId, tripItineraryAgentDetail[0]?.agentId, tripItineraryAgentDetail[0]?.referenceId, reason);
            }
            setReason('');
            setIsModalVisible(false);
        } catch (error) {
            message.error('Failed to save');
        }
    };

    const handleCancel = () => {
        setReason('');
        setIsModalVisible(false);
    };

    return (
        <div>
            <div className="flex justify-between">
                <p className="text-2xl font-semibold text-black">
                    {tripTravellerInfo?.fullName ?? ""}'s trip to {tripItineraryCities[0]?.cityName ?? ""} from {tripFromDate} to {tripTodate}
                </p>
                <div className="flex items-center gap-2">
                    {(tripTravellerInfo?.tripStatus ?? "") === "Upcoming" ?
                        <Button
                            onClick={() => onTripCancel(tripTravellerInfo?.tripId ?? "", "")}
                            className="h-10 text-lg border border-black px-7 py-1 flex items-center gap-2 hover:shadow-md hover:bg-gray-100 transition-all duration-300">
                            <img src={Images.deleteicon} className="w-[17px]" alt="cancel trip" />
                            Cancel Trip
                        </Button> : ""}
                    {(tripTravellerInfo?.tripStatus ?? "") !== "Upcoming" && (tripTravellerInfo?.tripStatus ?? "") === "Pending" ?
                        <Button
                            onClick={() => onTripDelete(tripTravellerInfo?.tripId ?? "", "")}
                            className="h-10 text-lg border border-black px-7 py-1 flex items-center gap-2 hover:shadow-md hover:bg-gray-100 transition-all duration-300">
                            <img src={Images.deleteicon} className="w-[17px]" alt="delete trip" />
                            Delete Trip
                        </Button> : ""}
                    <Button
                        onClick={showDrawer}
                        className="h-10 text-lg border border-black px-7 py-1 flex items-center gap-2 hover:shadow-md hover:bg-gray-100 transition-all duration-300">
                        <img src={Images.paymenthistory} className="w-[17px]" alt="payment history" />
                        Payment History
                    </Button>
                </div>
                <Drawer title="Payment History" width={520} onClose={onClose} open={open} mask={false}>
                    {tripPaymentHistory ? tripPaymentHistory.map(x => (
                        <div className="bg-gray-100 p-5 border rounded-lg mb-3" key={x.transactionId}>
                            <div className="flex justify-between">
                                <div>
                                    <p><span className="font-medium">Date:</span> {formatDateWithOrdinalSuffix(new Date(x.paymentDateTime), true) + " " + formatDateTime(new Date(x.paymentDateTime), "HH:mm AMPM")} </p>
                                    <p className="mt-2"><span className="font-medium">Transaction ID:</span> {x.transactionId} </p>
                                    <p className="mt-2"><span className="font-medium">Payment Method:</span> {x.paymentMethod} ending in {x.last4}</p>
                                </div>
                                <div>
                                    <p><span className="font-medium text-end">Amount:</span> ${x.amount}</p>
                                    {isNotNullUndefinedBlank(x.failedReason) ? (
                                        <Tag color="red" className="px-2.5 py-1 font-semibold text-sm mt-2 mr-0 w-[90px] flex justify-center rounded-full">
                                            Failed
                                        </Tag>
                                    ) : x.isPaymentRefunded ? (
                                        <Tag color="orange" className="px-2.5 py-1 font-semibold text-sm mt-2 mr-0 w-[90px] flex justify-center rounded-full">
                                            Refunded
                                        </Tag>
                                    ) : (
                                        <Tag color="green" className="px-2.5 py-1 font-semibold text-sm mt-2 mr-0 w-[90px] flex justify-center rounded-full">
                                            Successful
                                        </Tag>
                                    )}
                                </div>
                            </div>
                            {isNotNullUndefinedBlank(x.failedReason) ? <p className="mt-2"><span className="font-medium">Failed Reason:</span> Something went wrong try again Later</p> : ""}
                        </div>
                    )) : ""}
                </Drawer>
            </div>
            <div className="mt-6">
                <Row gutter={[24, 24]} className="trip-card flex justify-between">
                    <Col xs={24} md={24} lg={12}>
                        <Card title="Traveller Info" bordered={true} className="border-gray-300 bg-snow h-full">
                            <div className="justify-between text-base">
                                <div className="flex justify-between">
                                    <div className="flex">
                                        <p className="text-lg font-medium">{tripTravellerInfo?.fullName ?? ""}</p>
                                    </div>
                                </div>
                                <Row gutter={16} className="row-gap">
                                    {[
                                        { label: "Gender", value: tripTravellerInfo?.gender, icon: Images.malefemale },
                                        { label: "Phone", value: tripTravellerInfo?.phoneNo, icon: Images.phone },
                                        { label: "Language", value: tripTravellerInfo?.languageName, icon: Images.language },
                                        { label: "Email", value: tripTravellerInfo?.email, icon: Images.email, colSpan: 12 },
                                        { label: "Address", value: tripTravellerInfo?.address, icon: Images.address, colSpan: 12, iconWidth: "!w-3" },
                                    ].map((info, index) => (
                                        <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12} xxl={info.colSpan === 12 ? 12 : 8}>
                                            <div className="flex items-center gap-2.5 mt-4">
                                                <div className={`w-4 ${info.iconWidth || ''}`}>
                                                    <img src={info.icon} className="w-full" alt={info.label} />
                                                </div>
                                                <p className="text-base font-medium">{info.label}</p>
                                            </div>
                                            <div className="ml-7">
                                                <p className="text-base">{info.value ?? ""}</p>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} md={24} lg={12}>
                        <Card title="Trip Itinerary" className="bg-snow border-gray-300 h-full" extra={
                            <Col xs={24}>
                                <div className="flex items-center justify-between">
                                    <div className="flex gap-1">
                                        <p className="text-xl font-medium">Platform fees:</p>
                                    </div>
                                    <div>
                                        <p className="text-xl font-semibold ml-1">${parseFloat(tripTravellerInfo?.platformFee ?? "").toFixed(2)}</p>
                                    </div>
                                </div>
                            </Col>
                        }>
                            <div>
                                <p className="text-lg font-medium">Arrival Info:</p>
                            </div>
                            <Row gutter={16} className="row-gap">
                                {[
                                    { label: "Arrival City", value: tripTravellerInfo?.cityName, icon: Images.cities },
                                    { label: "Arrival Date & Time", value: arrivalDateTime, icon: Images.calendar },
                                    { label: "No. of People", value: tripTravellerInfo?.noOfPax, icon: Images.people },
                                    // { label: "Port Of Entry", value: tripTravellerInfo?.portOfEntry, icon: Images.arrivalport, iconWidth: "!w-3" },
                                    { label: "Pickup Address", value: tripItineraryAgentDetail[0]?.pickupAddress, icon: Images.port, colSpan: 12 },
                                ].map((info, index) => (
                                    <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12} xxl={info.colSpan === 12 ? 12 : 8}>
                                        <div className="flex items-center gap-2.5 mt-4">
                                            <div className={`w-4`}>
                                                <img src={info.icon} className="w-full" alt={info.label} />
                                            </div>
                                            <p className="text-base font-medium">{info.label}</p>
                                        </div>
                                        <div className="ml-7">
                                            <p className="text-sm">{info.value ?? ""}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <div className="mt-10">
                    <div className="w-full mt-4">
                        <Tabs defaultActiveKey="1" className="custom-tabs-col w-full bg-white" onTabClick={onChangeCityTab}>
                            {tripItineraryCities ? tripItineraryCities.map(x => (
                                <TabPane tab={x.cityName} key={x.referenceId}>
                                    <div className="bg-white">
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={8}>
                                                <div>
                                                    <Card bordered={true} className="bg-snow border-gray-300">
                                                        <div className="mt-2 justify-between text-base">
                                                            <div className="flex justify-end gap-2 items-center">
                                                                {tripItineraryAgentDetail[0]?.tripStatus === "Cancelled" && tripItineraryAgentDetail[0]?.isRefunded === false ? (
                                                                    <Tag
                                                                        onClick={() => updatePaymentRefund(tripTravellerInfo?.tripId ?? "", tripItineraryAgentDetail[0]?.scheduleId ?? "")}
                                                                        className="cursor-pointer px-2.5 py-1 font-semibold text-sm"
                                                                        color="cyan"
                                                                    >
                                                                        Refund
                                                                    </Tag>
                                                                ) : ""}
                                                                {tripItineraryAgentDetail[0]?.tripStatus === "Cancelled" && tripItineraryAgentDetail[0]?.isRefunded === true ? (
                                                                    <p className="font-semibold text-sm">( Refunded )</p>
                                                                ) : ""}
                                                                {(tripItineraryAgentDetail[0]?.tripStatus ?? "") === "Pending" ?
                                                                    <Tag
                                                                        onClick={() => onTripDelete(tripTravellerInfo?.tripId ?? "", tripItineraryAgentDetail[0]?.scheduleId ?? "")}
                                                                        className="cursor-pointer px-2.5 py-1 font-semibold text-sm border border-black flex items-center gap-2 hover:shadow-md hover:bg-gray-100 transition-all duration-300">
                                                                        Delete Schedule
                                                                    </Tag> : ""}
                                                                {(tripItineraryAgentDetail[0]?.tripStatus ?? "") === "Upcoming" ?
                                                                    <Tag
                                                                        onClick={() => onTripCancel(tripTravellerInfo?.tripId ?? "", tripItineraryAgentDetail[0]?.scheduleId ?? "")}
                                                                        className="cursor-pointer px-2.5 py-1 font-semibold text-sm border border-black flex items-center gap-2 hover:shadow-md hover:bg-gray-100 transition-all duration-300">
                                                                        Cancel Schedule
                                                                    </Tag> : ""}
                                                                <Tag rootClassName="m-0" color={tripItineraryAgentDetail[0]?.tripStatus === 'Pending' ? 'orange' : tripItineraryAgentDetail[0]?.tripStatus === 'Upcoming' ? 'blue' : tripItineraryAgentDetail[0]?.tripStatus === 'Completed' ? 'green' : tripItineraryAgentDetail[0]?.tripStatus === 'Running' ? 'purple' : tripItineraryAgentDetail[0]?.tripStatus === 'Expired' ? 'magenta' : 'red'} className="px-2.5 py-1 font-semibold text-sm">{tripItineraryAgentDetail[0]?.tripStatus}</Tag>
                                                            </div>
                                                            {tripItineraryAgentDetail[0]?.tripStatus === "Cancelled" && isNotNullUndefinedBlank(tripItineraryAgentDetail[0]?.cancelledReason) ? (
                                                                <div className="border border-[#cf1322] p-1 mt-2 rounded">
                                                                    <p className="text-[#cf1322]">{tripItineraryAgentDetail[0]?.cancelledReason}</p>
                                                                </div>
                                                            ) : ""}

                                                            {isNotNullUndefinedBlank(tripItineraryAgentDetail[0]?.rejectedReason) ? (
                                                                <div className="border border-[#cf1322] p-1 mt-2 rounded">
                                                                    <p className="text-[#cf1322]">{tripItineraryAgentDetail[0]?.rejectedReason}</p>
                                                                </div>
                                                            ) : ""}

                                                            <div className="flex justify-between mt-2">
                                                                <div className="flex items-center">
                                                                    <p className="font-semibold mr-2"><CalendarOutlined /> Stay Days:</p>
                                                                    <p>{tripItineraryAgentDetail.length} days</p>
                                                                </div>
                                                                <div>
                                                                </div>
                                                            </div>
                                                            <div className="flex mt-2">
                                                                <p className="pl-[21px] font-semibold mr-2">Dates:</p>
                                                                <p>{tripCityFromDate}</p>
                                                            </div>

                                                            <div className="flex mt-2">
                                                                <p className="pl-[21px] font-semibold mr-2">TripStartedAt Location:</p>
                                                                {travellerStartLocation && travellerStartLocation.lat !== 0 && travellerStartLocation.lng !== 0 ? (
                                                                    <span>
                                                                        Lat: {travellerStartLocation.lat}, Long: {travellerStartLocation.lng}
                                                                    </span>
                                                                ) : (
                                                                    <span></span>
                                                                )}
                                                            </div>

                                                            <div className="flex mt-2">
                                                                <p className="pl-[21px] font-semibold mr-2">TripEndedAt Location:</p>
                                                                {travellerEndLocation && travellerEndLocation.lat !== 0 && travellerEndLocation.lng !== 0 ? (
                                                                    <span>
                                                                        Lat: {travellerEndLocation.lat}, Long: {travellerEndLocation.lng}
                                                                    </span>
                                                                ) : (
                                                                    <span></span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {parseInt(tripItineraryAgentDetail[0]?.rating ?? "") > 0 ? (
                                                            <div className="mt-5 text-base flex justify-between">
                                                                <div>
                                                                    <p className="font-medium">Review Submitted!</p>
                                                                </div>
                                                                <div className="flex gap-1.5 text-lg">
                                                                    <Flex gap="middle" vertical>
                                                                        <Rate disabled className="review-icon-color" value={parseFloat(tripItineraryAgentDetail[0]?.rating ?? "")} />
                                                                    </Flex>
                                                                </div>
                                                            </div>
                                                        ) : ""}
                                                        {
                                                            isNotNullUndefinedBlank(tripItineraryAgentDetail[0]?.comment ?? "") ?
                                                                (<div className="border rounded p-2 mt-2 bg-white">
                                                                    <p>
                                                                        {tripItineraryAgentDetail[0]?.comment ?? ""}
                                                                    </p>
                                                                </div>) : ""
                                                        }
                                                    </Card>
                                                </div>
                                                <div className="mt-5">
                                                    <Card title="Attendant Info" bordered={true} className="bg-snow border-gray-300"
                                                        extra={
                                                            (((tripItineraryAgentDetail[0]?.rejectedReason ?? "") === "" && tripItineraryAgentDetail[0]?.referenceId) && (tripTravellerInfo?.tripStatus ?? "") === "Pending") ? (
                                                                <Button
                                                                    onClick={onRejectAgent}
                                                                    className="h-10 text-lg border border-black px-7 py-1 flex items-center gap-2 hover:shadow-md hover:bg-gray-100 transition-all duration-300"
                                                                >
                                                                    Cancel Attendant
                                                                </Button>
                                                            ) : null
                                                        }
                                                    >
                                                        <div>
                                                            <p className="text-lg font-medium">{tripItineraryAgentDetail[0]?.fullName ?? ""}</p>
                                                        </div>
                                                        <Row gutter={16} className="row-gap">
                                                            <Col xs={24} sm={12}>
                                                                <div className="flex items-center gap-2.5 mt-4">
                                                                    <div className="w-5">
                                                                        <img
                                                                            src={Images.malefemale}
                                                                            className="w-full h-full"
                                                                            alt="Gender"
                                                                        />
                                                                    </div>
                                                                    <p className="text-base">{tripItineraryAgentDetail[0]?.gender ?? ""}</p>
                                                                </div>
                                                            </Col>
                                                            <Col xs={24} sm={12}>
                                                                <div className="flex items-center gap-2.5 mt-4">
                                                                    <div className="w-5">
                                                                        <img
                                                                            src={Images.phone}
                                                                            className="w-full h-full"
                                                                            alt="Phone"
                                                                        />
                                                                    </div>
                                                                    <p className="text-base">{tripItineraryAgentDetail[0]?.phoneNo ?? ""}</p>
                                                                </div>
                                                            </Col>
                                                            <Col xs={24} sm={12}>
                                                                <div className="flex items-center gap-2.5 mt-4">
                                                                    <div className="w-5">
                                                                        <img
                                                                            src={Images.email}
                                                                            className="w-full h-full"
                                                                            alt="Email"
                                                                        />
                                                                    </div>
                                                                    <p className="text-base">{tripItineraryAgentDetail[0]?.email ?? ""}</p>
                                                                </div>
                                                            </Col>
                                                            <Col xs={24} sm={12}>
                                                                <div className="flex items-center gap-2.5 mt-4">
                                                                    <div className="w-5">
                                                                        <img
                                                                            src={Images.language}
                                                                            className="w-full h-full"
                                                                            alt="Language"
                                                                        />
                                                                    </div>
                                                                    <p className="text-base">{tripItineraryAgentDetail[0]?.languageName ?? ""}</p>
                                                                </div>
                                                            </Col>
                                                            <Col xs={24}>
                                                                <div className="flex items-center gap-2.5 mt-4">
                                                                    <div className="w-4">
                                                                        <img
                                                                            src={Images.address}
                                                                            className="w-full h-full"
                                                                            alt="Address"
                                                                        />
                                                                    </div>
                                                                    <p className="text-base">
                                                                        {tripItineraryAgentDetail[0]?.address ?? ""}
                                                                    </p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <div>
                                                            <div className="flex items-center justify-between mt-4">
                                                                <p className="text-lg font-semibold">Assignment info</p>
                                                                <div className="border border-t-0 border-r-0 border-l-0 border-gray-300 border-opacity-12 w-[calc(100%-150px)]"></div>
                                                            </div>
                                                            <Row gutter={16} className="row-gap">
                                                                <Col xs={24} sm={12}>
                                                                    <div className="flex items-center gap-2.5 mt-4">
                                                                        <div className="">
                                                                            <p className="text-base font-medium">Working days:</p>
                                                                        </div>
                                                                        <p className="text-base">{tripItineraryAgentDetail.length} days</p>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <div className="flex items-center gap-2.5 mt-4">
                                                                        <div className="">
                                                                            <p className="text-base font-medium">Service hours:</p>
                                                                        </div>
                                                                        <p className="text-lg font-semibold">{agentTotalServiceHours}</p>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <div className="flex items-center gap-2.5 mt-4">
                                                                        <div className="">
                                                                            <p className="text-base font-medium">Hourly rate:</p>
                                                                        </div>
                                                                        <p className="text-base">${tripItineraryAgentDetail[0]?.hourlyRate ?? ""}</p>
                                                                    </div>
                                                                </Col>

                                                            </Row>
                                                            <div className="flex items-center gap-2.5 mt-4">
                                                                <p className="text-base font-medium">TripStartedAt Location:</p>
                                                                <p className="text-base">
                                                                    {agentStartLocation && agentStartLocation.lat !== 0 && agentStartLocation.lng !== 0 ? (
                                                                        <span>
                                                                            Lat: {agentStartLocation.lat}, Long: {agentStartLocation.lng}
                                                                        </span>
                                                                    ) : (
                                                                        <span></span>
                                                                    )}
                                                                </p>
                                                            </div>

                                                            <div className="flex items-center gap-2.5 mt-4">
                                                                <p className="text-base font-medium">TripEndedAt Location:</p>
                                                                <p className="text-base">
                                                                    {agentEndLocation && agentEndLocation.lat !== 0 && agentEndLocation.lng !== 0 ? (
                                                                        <span>
                                                                            Lat: {agentEndLocation.lat}, Long: {agentEndLocation.lng}
                                                                        </span>
                                                                    ) : (
                                                                        <span></span>
                                                                    )}
                                                                </p>
                                                            </div>

                                                        </div>
                                                    </Card>
                                                </div>
                                                <div className="mt-5">
                                                    <Card className="bg-snow border-gray-300">
                                                        {tripItineraryAgentDetail ? tripItineraryAgentDetail.map(x => (
                                                            <div className="justify-between text-base pb-7 last:pb-0" key={x.scheduleId}>
                                                                <div className="flex justify-between items-center">
                                                                    <div className="flex">
                                                                        <p className="font-semibold mr-2"><CalendarOutlined /> Daily Schedule:</p>
                                                                        <p>
                                                                            {formatDateWithOrdinalSuffix(new Date(x.scheduleDate))} Starts from{" "}
                                                                            {formatDateTime(tripItineraryAgentDetail[0].fromTime, "hh:mm")}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="pl-[21px] mt-2">
                                                                    <div className="flex mt-2 items-start">
                                                                        <p className="font-semibold mr-2">Pickup Address:</p>
                                                                        <p className="flex-1">{x.pickupAddress}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="pl-[21px] mt-2">
                                                                    <div className="flex mt-2 items-start">
                                                                        <p className="font-semibold mr-2 whitespace-nowrap">
                                                                            Recorded Service Hrs:
                                                                        </p>
                                                                        {x.scheduleStartedAt ? (
                                                                            x.scheduleEndedAt ? (
                                                                                <p>
                                                                                    {Math.floor(x.recordedMinutes / 60)} hrs {x.recordedMinutes % 60} mins
                                                                                    Started at {scheduleStartDateTime} to {scheduleEndDateTime}
                                                                                </p>
                                                                            ) : (
                                                                                <p>
                                                                                    {Math.floor(x.recordedMinutes / 60)} hrs {x.recordedMinutes % 60} mins
                                                                                    Started at {scheduleStartDateTime}
                                                                                </p>
                                                                            )
                                                                        ) : (
                                                                            <p>Trip not started yet</p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="flex items-center justify-between mt-4">
                                                                        <p className="text-lg font-semibold">Attendant charges for day</p>
                                                                        <div>
                                                                            <Tag color={x.paymentStatus === "Platform Fee Paid" ? "purple" : x.paymentStatus === "Overdue" ? "red" : x.paymentStatus === "Payment Failed" ? "red" : x.paymentStatus === "Paid" ? "green" : "orange"} className="px-2.5 py-1 font-semibold text-sm">{x.paymentStatus}</Tag>
                                                                        </div>
                                                                    </div>
                                                                    <Row gutter={16} className="row-gap">
                                                                        <Col xs={24}>
                                                                            <div className="flex items-center justify-between gap-2.5 mt-4">
                                                                                <div className="flex gap-2">
                                                                                    <p className="text-base font-medium">Requested Serviceable hrs</p>
                                                                                    <CloseOutlined />
                                                                                    <p className="text-base font-medium">Hourly rate</p>
                                                                                </div>
                                                                                <div className="">
                                                                                    <p className="text-base font-medium">Payable amount</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex items-center justify-between gap-2.5">
                                                                                <div className="flex gap-2">
                                                                                    <p className="text-sm">{agentTotalServiceHours}</p>
                                                                                    <CloseOutlined />
                                                                                    <p className="text-sm">${x.hourlyRate ?? ""}</p>
                                                                                </div>
                                                                                <div className="">
                                                                                    <p className="text-sm">${parseFloat(x.chargeAmount).toFixed(2)}</p>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={24}>
                                                                            <div className="flex items-center justify-between border-b border-gray-300 pb-2.5 mt-4">
                                                                                <div className="flex gap-1">
                                                                                    <p className="text-base font-medium">Taxes:</p>
                                                                                    <p className="text-base">({parseFloat(tripTravellerInfo?.taxPercentage ?? '0').toFixed(2)}%)</p>
                                                                                </div>
                                                                                <div>
                                                                                    <p>${parseFloat(x.taxAmount).toFixed(2)}</p>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={24}>
                                                                            <div className="flex items-center justify-end gap-3 mt-4">
                                                                                <div className="flex gap-1">
                                                                                    <p className="text-lg font-semibold">Total</p>
                                                                                </div>
                                                                                <div>
                                                                                    {/* <p className="text-lg font-semibold">${parseFloat(x.chargeAmount) + parseFloat(x.taxAmount)}</p> */}
                                                                                    <p className="text-lg font-semibold">${parseFloat(x.finalChargeAmount).toFixed(2)}</p>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        )) : ""}
                                                    </Card>
                                                </div>
                                                <div className="mt-5">
                                                    <Card title="Overall Bill summary"
                                                        // extra={
                                                        //     <div>
                                                        //         <Tag color="purple" className="px-2.5 py-1 font-semibold text-sm">Platform fee paid</Tag>
                                                        //         <Tag color="yellow" className="px-2.5 py-1 font-semibold text-sm">Due</Tag>
                                                        //         <Tag color="blue" className="px-2.5 py-1 font-semibold text-sm">Paid 1st Jul</Tag>
                                                        //         <Tag color="red" className="px-2.5 py-1 font-semibold text-sm">Overdue 1st Jul</Tag>
                                                        //         <Tag color="green" className="px-2.5 py-1 font-semibold text-sm">Fully paid</Tag>
                                                        //     </div>
                                                        // }
                                                        className="bg-snow border-gray-300">
                                                        {/* <div>
                                                            <Row gutter={16} className="row-gap">
                                                                <Col xs={24}>
                                                                    {tripItineraryAgentDetail ? tripItineraryAgentDetail.map(x => (
                                                                        <div className="flex items-center justify-between gap-2.5 mt-4" key={x.scheduleId}>
                                                                            <div className="flex gap-2">
                                                                                <p className="text-base font-medium">Payable amount {formatDateWithOrdinalSuffix(new Date(x.scheduleDate))}</p>
                                                                            </div>
                                                                            <div className="">
                                                                                <p className="text-base">${parseFloat(x.chargeAmount).toFixed(2)}</p>
                                                                            </div>
                                                                        </div>
                                                                    )) : ""}
                                                                </Col>
                                                                <Col xs={24}>
                                                                    <div className="flex items-center justify-between mt-4 border-b border-gray-300 pb-2.5">
                                                                        <div className="flex gap-1">
                                                                            <p className="text-base font-medium">Taxes:</p>
                                                                            <p className="text-base font-medium">({parseFloat(tripTravellerInfo?.taxPercentage ?? '0').toFixed(2)}%)</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>${parseFloat(agentTotalTaxAmount).toFixed(2)}</p>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24}>
                                                                    <div className="flex items-center border-b border-gray-300 pb-2.5 justify-between mt-4">
                                                                        <div className="flex gap-1">
                                                                            <p className="text-base font-medium">Platform fees:</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>${tripTravellerInfo?.platformFee ?? ""}</p>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24}>
                                                                    <div className="flex items-center justify-end gap-3 mt-4">
                                                                        <div className="flex gap-1">
                                                                            <p className="text-lg font-semibold">Total</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="text-lg font-semibold">${parseFloat(agentTotalChargeAmount).toFixed(2)}</p>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div> */}
                                                        <div>
                                                            <div className="flex items-center justify-between mt-4">
                                                                <p className="text-lg font-semibold">The attendant will be charged {tripItineraryAgentDetail[0]?.agentCommissionPercentage}% as a platform commission on their total earnings.</p>
                                                            </div>
                                                            <Row gutter={16} className="row-gap">
                                                                <Col xs={24}>
                                                                    {tripItineraryAgentDetail ? tripItineraryAgentDetail.map(x => (
                                                                        <div className="flex items-center justify-between gap-2.5 mt-4" key={x.scheduleId}>
                                                                            <div className="flex gap-2">
                                                                                <p className="text-base font-medium">Earning for {formatDateWithOrdinalSuffix(new Date(x.scheduleDate))}</p>
                                                                            </div>
                                                                            <div className="">
                                                                                <p className="text-base">${parseFloat(x.chargeAmount).toFixed(2)}</p>
                                                                            </div>
                                                                        </div>
                                                                    )) : ""}

                                                                </Col>
                                                                <Col xs={24}>
                                                                    <div className="flex items-center justify-end gap-3 mt-4">
                                                                        <div className="flex gap-1">
                                                                            <p className="text-base font-medium">Total earning</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>${parseFloat(agentChargeAmount).toFixed(2)}</p>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24}>
                                                                    <div className="flex items-center justify-between mt-4 border-b border-gray-300 pb-2.5">
                                                                        <div className="flex gap-1">
                                                                            <p className="text-base font-medium">Platform commission: ({parseFloat(tripItineraryAgentDetail[0]?.agentCommissionPercentage).toFixed(2)}%)</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>-${parseFloat(agentTotalCommissionAmount).toFixed(2) ?? ""}</p>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24}>
                                                                    <div className="flex items-center justify-end gap-3 mt-4">
                                                                        <div className="flex gap-1 ">
                                                                            <p className="text-lg font-semibold">Total</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className="text-lg font-semibold">${(
                                                                                (parseFloat(agentChargeAmount) - parseFloat(agentTotalCommissionAmount)).toFixed(2))}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Card>
                                                </div>
                                            </Col>
                                            {
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={16} className="">
                                                    <div className="flex gap-5 h-full">
                                                        <Card className="map-col w-full h-full 2xl:h-3/5" hidden={tripItineraryAgentDetail[0]?.tripStatus !== 'Running'}>
                                                            <div className="h-full">
                                                                <p className="text-xl text-center font-semibold mb-4">
                                                                    Traveller's <span className="font-normal text-base">current location</span>
                                                                </p>
                                                                <div className="h-screen 2xl:h-[calc(100%-89px)] relative">
                                                                    <LoadScript googleMapsApiKey="AIzaSyAde9vjTOBAPSzLCMmi-9OtFogrTxwqksc">
                                                                        <GoogleMap
                                                                            id="traveller-map"
                                                                            mapContainerStyle={containerStyle}
                                                                            center={travellerCenter}
                                                                            zoom={10}
                                                                        >
                                                                            {userLocations && userLocations.length > 0 &&
                                                                                userLocations.map((user) => (
                                                                                    user.location && (
                                                                                        <Marker
                                                                                            key={user.userId}
                                                                                            position={{
                                                                                                lat: user.location.latitude,
                                                                                                lng: user.location.longitude
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                ))}
                                                                        </GoogleMap>
                                                                        {!isNotNullUndefinedBlank(lastUpdatedTraveller) && tripItineraryAgentDetail[0]?.tripStatus === 'Running' && (
                                                                            <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                                                                <div className="bg-white p-6 rounded shadow-lg w-3/4">
                                                                                    <div className="flex items-center gap-2">
                                                                                        <div className="w-7">
                                                                                            <img src={Images.disablelocation} className="w-full" alt="Disable loction " />
                                                                                        </div>
                                                                                        <p className="text-lg font-semibold">GPS Disabled</p>
                                                                                    </div>
                                                                                    <p className="mt-3">We are unable to track the current location because GPS is disabled. Please contact the user to enable GPS in their device settings to allow us to get accurate location tracking for their trip.</p>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </LoadScript>
                                                                </div>
                                                                <div className="flex justify-between items-center mt-3">
                                                                    <p className="">Location was updated at {lastUpdatedTraveller}</p>
                                                                    {/* <Button onClick={() => fetchUserLocations(tripTravellerInfo?.travellerId ?? "", tripItineraryAgentDetail[0].agentId)} className="flex justify-between items-center">
                                                                        <RedoOutlined />Refresh location
                                                                    </Button> */}
                                                                </div>
                                                            </div>
                                                        </Card>
                                                        <Card className="map-col w-full h-full 2xl:h-3/5" hidden={tripItineraryAgentDetail[0]?.tripStatus !== 'Running'}>
                                                            <div className="h-full">
                                                                <p className="text-xl text-center font-semibold mb-4">
                                                                    Attendant's <span className="font-normal text-base">current location</span>
                                                                </p>
                                                                <div className="h-screen 2xl:h-[calc(100%-89px)] relative">
                                                                    <LoadScript googleMapsApiKey="AIzaSyAde9vjTOBAPSzLCMmi-9OtFogrTxwqksc">
                                                                        <GoogleMap
                                                                            id="trip-attendynt-map"
                                                                            mapContainerStyle={containerStyle}
                                                                            center={agentCenter}
                                                                            zoom={10}
                                                                        >
                                                                            {userLocations && userLocations.length > 0 &&
                                                                                userLocations.map((user) => (
                                                                                    user.location && (
                                                                                        <Marker
                                                                                            key={user.userId}
                                                                                            position={{
                                                                                                lat: user.location.latitude,
                                                                                                lng: user.location.longitude
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                ))}
                                                                        </GoogleMap>
                                                                        {!isNotNullUndefinedBlank(lastUpdatedAgent) && tripItineraryAgentDetail[0]?.tripStatus === 'Running' && (
                                                                            <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                                                                                <div className="bg-white p-6 rounded shadow-lg w-3/4">
                                                                                    <div className="flex items-center gap-2">
                                                                                        <div className="w-7">
                                                                                            <img src={Images.disablelocation} className="w-full" alt="Disable loction " />
                                                                                        </div>
                                                                                        <p className="text-lg font-semibold">GPS Disabled</p>
                                                                                    </div>
                                                                                    <p className="mt-3">We are unable to track the current location because GPS is disabled. Please contact the user to enable GPS in their device settings to allow us to get accurate location tracking for their trip.</p>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </LoadScript>
                                                                </div>
                                                                <div className="flex justify-between items-center mt-3">
                                                                    <p className="">Location was updated at {lastUpdatedAgent}</p>
                                                                    {/* <Button onClick={() => fetchUserLocations(tripTravellerInfo?.travellerId ?? "", tripItineraryAgentDetail[0].agentId)} className="flex justify-between items-center">
                                                                        <RedoOutlined />Refresh location
                                                                    </Button> */}
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                    </div>
                                </TabPane>
                            )) : ""}
                        </Tabs>
                    </div>
                </div>
            </div>

            <Modal
                title={<span className="custom-modal-title">{getModalTitle()}</span>}
                open={isModalVisible}
                onOk={handleSave}
                onCancel={handleCancel}
                closeIcon={<CloseOutlined className="text-gray-500 hover:text-black" />}
                maskClosable={false}
                footer={[
                    modalType !== 'cancel' ? (
                        <Button key="back" onClick={handleCancel} className="hover:!text-black hover:!border-black">
                            Cancel
                        </Button>
                    ) : null,
                    modalType !== 'cancel' ? (
                        <Button key="submit" onClick={handleSave} className="bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black">
                            Confirm
                        </Button>
                    ) : null,
                ]}
            >
                {getModelContent()}
            </Modal>

        </div>
    );
};
export default TripDetails;