import React, { useEffect, useRef, useState } from "react";
import { Space, Table, Tag, Button, Input, message, Select, Drawer, Col, Row, Form, Tabs, ConfigProvider, Modal, Spin, Flex } from "antd";
import IResponse from '../types/response';
import { EditOutlined, DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import { useCompleteUserProfile, useSendVerificationCode, useUpdateVerifiedStatus, useVerifyUserVerificationCode } from "../services/user.service";
import { useFetchCountry } from '../services/countries.service';
import { useFetchStates } from '../services/states.service';
import { useFetchCities } from '../services/cities.service';
import { compareObjects, formatDateTime, isNotNullUndefined, isNotNullUndefinedBlank, isValidEmail, isValidPhoneNumber } from "../helpers/common";
import Images from "../assets/image";
import Pagination from "../helpers/pagination";
import { useFetchTravellerList, useFetchTravellerProfileInfo, useFetchTravellerStatuses, useUpdateUserStatus } from "../services/traveller.service";
import { awsS3config } from "../helpers/config";
import { DeleteObjectCommand, DeleteObjectCommandInput, S3, ObjectCannedACL } from '@aws-sdk/client-s3';
import { Upload as S3Upload } from '@aws-sdk/lib-storage';
import { storage } from "../utils/storage";
import { useNavigate } from "react-router-dom";
import { useTimer } from "../helpers/useTimer";
import { eventEmitter } from "../helpers/eventemitter";

const Traveller: React.FC = () => {

    const { Search } = Input;
    const { Column } = Table;
    const [form] = Form.useForm();
    const [otpForm] = Form.useForm();
    const navigate = useNavigate();
    const [loading, setIsLoading] = useState(false);
    const [travellerListData, setTravellerList] = useState<any>([]);
    const [profileImage, setProfileImage] = useState(Images.userprofile);
    const [countries, setCountry] = useState<any[]>([]);
    // const [isEditing, setIsEditing] = useState(false);
    // const [editUser, setEditingUser] = useState<any>(null);
    // const [states, setState] = useState<any[]>([]);
    // const [cities, setCity] = useState<any[]>([]);

    // const [countryOptions, setCountryOptions] = useState<any[]>([]);
    const [stateOptions, setStateOptions] = useState<any[]>([]);
    const [cityOptions, setCityOptions] = useState<any[]>([]);

    const [selectedCountry, setSelectedCountry] = useState<any>();
    const [open, setOpen] = useState(false);
    const filters = useRef({ statusFilter: 'All' });
    const { TabPane } = Tabs;
    const pageNumber = useRef(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [travellerAllStatuses, setTravellerAllStatuses] = useState<any[]>([]);
    const [travellerStatuses, setTravellerStatuses] = useState<any[]>([]);
    const travellerStatusOptions = useRef<any>(null);
    const [modalType, setModalType] = useState<'delete' | 'update' | ''>('');
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [travellerId, setDeletedTravellerId] = useState('');
    const [formUpdateValues, setFormUpdateValues] = useState<any>({});
    const [isEmailVerified, setEmailVerified] = useState(false);
    const [isSmsVerified, setSmsVerified] = useState(false);
    const isEmailType = useRef(false);
    const [isVerifyModalVisible, setIsVerifyModalVisible] = useState(false);
    const codeType = useRef('');
    const [modalLoading, setModalLoading] = useState(false);
    const { resendTimer, resetTimer } = useTimer();
    const formInitialValues = useRef<any>({});
    const formUpdatedValues = useRef<any>({});
    const searchValue = useRef<string>("");
    const phoneNo = useRef<string>('');
    const email = useRef<string>('');
    const phoneNoDialingCode = useRef<string>("");
    const [reason, setReason] = useState('');
    const [selectedEmergencyCode, setSelectedEmergencyCode] = useState<any>();

    //#region on-load useEffect operations
    useEffect(() => {
        let userRoleId = parseInt(storage.getUserRoleId() ?? "0");
        if (userRoleId !== 1) {
            navigate("/");
        }
        else {
            setIsLoading(true);
            fetchTravellerStatus();
            fetchCountry();
            fetchTravellerList("", pageNumber.current, pageSize, searchValue.current ?? "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, filters.current]);
    //#endregion on-load useEffect operations

    //#region fetch countries, states, cities.

    const fetchCountry = async () => {
        fetchCountryMutation.mutate({
            searchFilter: "",
            isServiceable: ""
        });
    };

    const fetchCountryMutation = useFetchCountry({
        onSuccess: (response: any) => {
            if (response != null && response !== undefined) {
                setCountry(response);
            } else {
                message.error("Unexpected response format:", response);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });

    const fetchState = async (countryId: string = "") => {
        form.setFieldValue("state", "");
        form.setFieldValue("city", "");
        fetchStateMutation.mutate({
            countryId: countryId,
            searchFilter: ""
        });
    };

    const fetchStateMutation = useFetchStates({
        onSuccess: (response: any, request) => {
            if (isNotNullUndefined(response)) {
                if (isNotNullUndefinedBlank(request.countryId)) {
                    setStateOptions(response.data);
                }
            } else {
                message.error("Unexpected response format:", response);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });

    const fetchCity = async (stateId: string = "", countryId: string = "") => {
        form.setFieldValue("city", "");
        if (isNotNullUndefinedBlank(stateId) && isNotNullUndefinedBlank(countryId)) {
            fetchCityMutation.mutate({
                stateId: stateId,
                countryId: countryId,
                searchFilter: "",
                isFavourite: "",
                isServiceable: ""
            });
        }
    };

    const fetchCityMutation = useFetchCities({
        onSuccess: (response: any, request) => {
            if (isNotNullUndefined(response)) {
                if (isNotNullUndefinedBlank(request.stateId) && isNotNullUndefinedBlank(request.countryId)) {
                    setCityOptions(response.data);
                }
            } else {
                message.error("Unexpected response format:", response);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });

    const fetchStateOptions = (countryId: any) => {
        setStateOptions([]);
        fetchState(countryId);
    }

    const fetchCityOptions = (stateId: any, countryId: any) => {
        setCityOptions([]);
        fetchCity(stateId, countryId);
    }

    //#endregion fetch countries, states, cities.

    //#region traveller operations

    const onSearch = (value: any) => {
        pageNumber.current = 1;
        setPageSize(10);
        fetchTravellerList("", pageNumber.current, pageSize, value);
        searchValue.current = value;
    };

    const tabClicked = (key: number) => {
        const status = travellerStatuses.find((x: any) => x.statusId === key);
        if (status) {
            filters.current.statusFilter = status.statusName;
            pageNumber.current = 1;
            fetchTravellerList("", pageNumber.current, pageSize, searchValue.current ?? "");
        }
    };

    const showDrawer = (record: any) => {
        setIsLoading(false);
        fetchTravellerProfileInfo(record.travellerId);
    }

    const onDeleteTraveller = (record: any) => {
        setModalType('delete');
        setDeletedTravellerId(record.travellerId);
        setFormUpdateValues({ fullName: record.fullName, status: 100 });
        setIsConfirmModalVisible(true);
    }

    //#endregion traveller operations

    //#region fetch traveller operations

    const fetchTravellerList = async (travellerId: string, pageNumber: number, pageSize: number, search: string) => {
        setIsLoading(true);
        setTravellerList([]);
        fetchTravellerListMutation.mutate({ travellerId: travellerId, statusFilter: filters.current.statusFilter, searchFilter: search, pageNumber: pageNumber, pageSize: pageSize });
    };

    const fetchTravellerListMutation = useFetchTravellerList({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {

                if (isNotNullUndefined(response.data) && response.data.length > 0) {
                    const formattedData = response.data.map((data: any) => ({
                        ...data,
                        createdAt: formatDateTime(data.createdAt, "DD/MM/YYYY HH:mm AMPM"),
                        statusName: [data.statusName]
                    }));
                    setTravellerList(formattedData);
                    const recordCount = formattedData[0].recordCount;
                    setTotalPages(Math.ceil(recordCount / pageSize));
                    setIsLoading(false);
                } else {
                    setTravellerList([]);
                    setIsLoading(false);
                }
            } else {
                message.error(response.statusMessage);
                setIsLoading(false);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
            setIsLoading(false);
        }
    });

    const fetchTravellerStatus = async () => {
        fetchTravellerStatusMutation.mutate({});
    }

    const fetchTravellerStatusMutation = useFetchTravellerStatuses({
        onSuccess: (response: any) => {
            if (isNotNullUndefined(response) && response.statusCode === 200) {
                response.data.unshift({ statusId: 0, statusCode: '', statusName: 'All', statusType: '' });
                setTravellerAllStatuses(response.data);
                setTravellerStatuses(response.data.filter((x: any) => [0, 1, 10, 99, 100].includes(x.statusId)));
            } else {
                message.error("Unexpected response format:", response);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });

    //#endregion fetch traveller operations

    //#region fetch traveller-data on show edit-drawer.

    const fetchTravellerProfileInfo = async (agentId: string) => {
        fetchTravellerProfileInfoMutation.mutate(agentId);
    };

    const fetchTravellerProfileInfoMutation = useFetchTravellerProfileInfo({
        onSuccess: async (response: any) => {
            if (response?.data) {
                const travellerProfile = response.data;
                phoneNoDialingCode.current = response.data.phoneNoDialingCode;
                formInitialValues.current = {
                    travellerId: travellerProfile.travellerId,
                    fullName: travellerProfile.fullName,
                    status: travellerProfile.travellerStatusId,
                    phoneNoDialingCode: phoneNoDialingCode.current,
                    phoneNoCountryId: travellerProfile.phoneNoCountryId,
                    phoneNo: travellerProfile.phoneNo,
                    email: travellerProfile.email,
                    gender: travellerProfile.gender,
                    country: travellerProfile.countryId,
                    state: travellerProfile.stateId,
                    city: travellerProfile.cityId,
                    address: travellerProfile.address,
                    isEmailVerified: travellerProfile.isEmailVerified,
                    isPhoneNoVerified: travellerProfile.isPhoneNoVerified,
                    emergencyName: travellerProfile.emergencyName,
                    emergencyMobileNumberDialingCode: travellerProfile.emergencyMobileNumberDialingCode,
                    emergencyMobileNumber: travellerProfile.emergencyMobileNumber
                };
                travellerStatusOptions.current = (travellerAllStatuses.length > 0) ? travellerAllStatuses.filter((x: any) => [1, 100, travellerProfile.travellerStatusId].includes(x.statusId)) : [];

                fetchStateOptions(travellerProfile.countryId.toString());
                fetchCityOptions((parseInt(travellerProfile.stateId) > 0 ? travellerProfile.stateId.toString() : ""), travellerProfile.countryId.toString());
                setProfileImage(travellerProfile.imageUrl || Images.userprofile);

                email.current = travellerProfile.email;
                phoneNo.current = travellerProfile.phoneNo;
                setEmailVerified(travellerProfile.isEmailVerified);
                setSmsVerified(travellerProfile.isPhoneNoVerified);

                const fetchEmergencyNoDialingCode = countries.find((x) => x.dialingCode?.trim() === travellerProfile.emergencyMobileNumberDialingCode?.trim());
                if (fetchEmergencyNoDialingCode) {
                    const defaultOption = {
                        value: fetchEmergencyNoDialingCode.countryId,
                        label: (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={fetchEmergencyNoDialingCode.imageUrl} className='w-5' alt="Country Flag" style={{ marginRight: 8 }} />
                                {fetchEmergencyNoDialingCode.dialingCode} {fetchEmergencyNoDialingCode.countryName}
                            </div>
                        )
                    };
                    const option = { value: defaultOption.value, label: defaultOption.label.props.children };
                    handleDialingCodeChange(option, "emergencyNo");
                }
                const fetchPhoneNoDialingCode = countries.find((x) => x.dialingCode?.trim() === phoneNoDialingCode.current?.trim());
                if (fetchPhoneNoDialingCode) {
                    const dialingCodeOption = {
                        value: fetchPhoneNoDialingCode.dialindCode,
                        label: (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={fetchPhoneNoDialingCode.imageUrl} className='w-5' alt="Country Flag" style={{ marginRight: 8 }} />
                                {fetchPhoneNoDialingCode.dialingCode} {fetchPhoneNoDialingCode.countryName}
                            </div>
                        )
                    };
                    const option = { value: dialingCodeOption.value, label: dialingCodeOption.label.props.children };
                    handleDialingCodeChange(option, "phoneNo");
                }
                form.setFieldsValue({
                    travellerId: travellerProfile.travellerId,
                    fullName: travellerProfile.fullName ?? "",
                    email: travellerProfile.email ?? "",
                    phoneNo: travellerProfile.phoneNo,
                    gender: travellerProfile.gender,
                    imageUrl: travellerProfile.imageUrl,
                    address: travellerProfile.address ?? "",
                    country: parseInt(travellerProfile.countryId ?? 0) > 0 ? parseInt(travellerProfile.countryId) : null,
                    state: parseInt(travellerProfile.stateId ?? 0) > 0 ? parseInt(travellerProfile.stateId) : null,
                    city: parseInt(travellerProfile.cityId ?? 0) > 0 ? parseInt(travellerProfile.cityId) : null,
                    emergencyName: travellerProfile.emergencyName,
                    emergencyMobileNumberDialingCode: travellerProfile.emergencyMobileNumberDialingCode ?? "",
                    emergencyMobileNumber: travellerProfile.emergencyMobileNumber,
                    status: travellerProfile.travellerStatusId ?? "",
                    phoneNoDialingCode: phoneNoDialingCode.current,
                    phoneNoCountryId: travellerProfile.phoneNoCountryId,
                    isEmailVerified: travellerProfile.isEmailVerified,
                    isPhoneNoVerified: travellerProfile.isPhoneNoVerified,
                });
                eventEmitter.emit("clickableChange", true);
                setOpen(true);
            }
        },
        onError: (error: any) => {
            alert(error.message);
        },
    });

    //#endregion fetch traveller-data on show edit-drawer.

    //#region traveller edit-drawer operations

    const handleImageChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            if (isNotNullUndefinedBlank(profileImage) && profileImage !== Images.userprofile) {
                onDeleteFeatureImageFile();
            }
            const fileName = "traveller_profile" + new Date().getTime() + "." + file.name.split(".")[1];
            try {

                const bucket = new S3({
                    credentials: {
                        accessKeyId: awsS3config.accessKeyId,
                        secretAccessKey: awsS3config.secretAccessKey,
                    },
                    region: awsS3config.region,
                });

                const params = {
                    Bucket: awsS3config.bucketName,
                    Key: "temp/" + fileName,
                    Body: file,
                    ContentType: file.type,
                    ACL: ObjectCannedACL.public_read_write
                };

                const upload = new S3Upload({
                    client: bucket,
                    params: params,
                });
                upload.done().then((data) => {
                    const fileUrl = (data["Location"] !== '' && data["Location"] !== null && data["Location"] !== undefined) ? data["Location"] : "";
                    file.url = fileUrl;
                    setProfileImage(fileUrl);
                },
                    (err) => {
                        let errorJson = JSON.stringify(err);
                        let errorObject = JSON.parse(errorJson);
                        let msg = (errorObject["message"] !== '' && errorObject["message"] !== null && errorObject["message"] !== undefined) ? errorObject["message"] :
                            'Something went wrong. Please try again!';
                        message.error(msg);
                        return false;
                    });
            } catch (error) {
                message.error("Error uploading file.");
                return false;
            }

        }
    };

    const onDeleteFeatureImageFile = async () => {
        if (isNotNullUndefinedBlank(profileImage)) {
            const fileName = profileImage?.split('/').pop();
            if (isNotNullUndefinedBlank(fileName)) {
                try {
                    const s3Client = new S3({
                        credentials: {
                            accessKeyId: awsS3config.accessKeyId,
                            secretAccessKey: awsS3config.secretAccessKey,
                        },
                        region: awsS3config.region,
                    });

                    const params: DeleteObjectCommandInput = {
                        Bucket: awsS3config.bucketName,
                        Key: profileImage?.includes("/temp/") ? "temp/" + fileName : profileImage?.includes("/attendants/") ? "Attendants/" + fileName : "Traveller/" + fileName
                    };

                    const command = new DeleteObjectCommand(params);
                    await s3Client.send(command);
                }
                catch (error) {
                    message.error("Error uploading file.");
                    return false;
                }
                setProfileImage("");
            }
        }
    };

    const handleEditClick = () => {
        const inputElement = document.getElementById('profileImageInput');
        if (inputElement) {
            inputElement.click();
        }
    };

    const onClose = () => {
        setOpen(false);
        setSelectedEmergencyCode(null);
        setSelectedCountry(null);
        eventEmitter.emit("clickableChange", false);
        // setIsEditing(false);
        // setEditingUser(null);
        form.resetFields();
    };

    const { Option } = Select;

    const onGenderChange = (value: string) => {
        switch (value) {
            case 'male':
                form.setFieldsValue({ note: 'Hi, man!' });
                break;
            case 'female':
                form.setFieldsValue({ note: 'Hi, lady!' });
                break;
            default:
        }
    };

    const handleDialingCodeChange = (option: any, type: string = "") => {
        const updateLabel = (option: any) => {
            if (isNotNullUndefined(option)) {
                if (Array.isArray(option.label)) {
                    return { ...option, label: option.label.slice(0, 2) };
                } else {
                    const newLabel = { ...option.label, props: { ...option.label.props, children: [...option.label.props.children.slice(0, 3), '', ...option.label.props.children.slice(4)] } };
                    option.label = newLabel;
                    return option;
                }
            } else {
                return null;
            }
        };

        const updatedOption = updateLabel(option);

        if (type === 'phoneNo') {
            setSelectedCountry(updatedOption);
        } else if (type === 'emergencyNo') {
            setSelectedEmergencyCode(updatedOption);
        }
    };

    const onChangePhoneInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;

        if (!/^[0-9]*$/.test(value)) {
            value = value.replace(/[^0-9]/g, '');
        }
        if (value.length > 12) {
            value = value.slice(0, 12);
        }
        form.setFieldValue(e.target.id, value);
        if (e.target.id === "phoneNo") {
            phoneNo.current = value;
        }
    };

    const onChangeEmail = (e: { target: { value: any; }; }) => {
        let value = e.target.value;
        form.setFieldValue("email", value);
        email.current = value;
    };

    //#endregion traveller edit-drawer operations

    //#region confirmation modal operations

    const getModalTitle = () => {
        let message = "";
        if (modalType === 'delete') {
            message = "Confirm Deactivation!";
        } else if (modalType === 'update') {
            message = "Confirm Update!";
        }
        return message;
    }

    const getModelContent = () => {
        let modalContent = <></>;
        if (modalType === "delete") {
            modalContent = (
                <>
                    <p className="mb-4">Are you sure you want to deactivate <b>{formUpdateValues.fullName}</b></p>
                    <b className="block mb-2">Reason for Deactivation:</b>
                    <Input.TextArea
                        rows={4}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        placeholder={`Enter reason for Deactivation`}
                        className="border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                    />
                </>
            );
        } else if (modalType === 'update') {
            const changedStatus = travellerStatuses.find((x: any) => x.statusId === formUpdateValues.statusId);
            const isReasonRequired = ['Deactivated'].includes(changedStatus?.statusName);

            modalContent = (
                <>
                    <p className="mb-4">Are you sure you want to {modalType} the details for <b>{formUpdateValues.fullName}</b>? This action cannot be undone.</p>
                    {isReasonRequired && (
                        <>
                            <b className="block mb-2">Reason for {changedStatus.statusName}:</b>
                            <Input.TextArea
                                rows={4}
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                placeholder={`Enter reason for ${changedStatus.statusName}`}
                                className="border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                            />
                        </>
                    )}
                </>
            );
        } else {
            modalContent = (
                <p>Are you sure you want to <b>Update</b> this Traveller's Profile request? Once Updated, the traveller can be edited and their details will be updated accordingly.<b>Do you wish to proceed?</b></p>
            );
        }
        return modalContent;
    }

    const handleSave = () => {
        try {
            if (modalType === 'delete') {
                if (!reason) {
                    message.error(`Please provide a reason for deactivate user.`);
                    return;
                }
                if (reason.length > 300) {
                    message.error('The reason cannot exceed 300 characters.');
                    return;
                }
                updateUserStatus.mutate({ travellerId: travellerId, statusCode: "DEACTIVATED", reason: reason });
                message.success("Traveller deactivated successfully.")
            } else if (modalType === 'update') {
                const changedStatus = travellerStatuses.find((x: any) => x.statusId === formUpdateValues.statusId);
                const isReasonRequired = ['Deactivated'].includes(changedStatus?.statusName);

                if (isReasonRequired && !reason) {
                    message.error(`Please provide a reason for ${changedStatus.statusName}.`);
                    return;
                }
                if (reason.length > 300) {
                    message.error('The reason cannot exceed 300 characters.');
                    return;
                }
                setIsLoading(true);
                updateUserProfile.mutate({
                    travellerId: formUpdateValues.travellerId,
                    fullName: formUpdateValues.fullName,
                    email: formUpdateValues.email,
                    phoneNoDialingCode: formUpdateValues.phoneNoDialingCode?.trim(),
                    phoneNo: formUpdateValues.phoneNo,
                    imageUrl: formUpdateValues.imageUrl,
                    gender: formUpdateValues.gender ?? "",
                    address: formUpdateValues.address,
                    countryId: formUpdateValues.countryId ?? 0,
                    stateId: formUpdateValues.stateId ?? 0,
                    cityId: formUpdateValues.cityId ?? 0,
                    reason: reason ?? "",
                    emergencyName: formUpdateValues.emergencyName,
                    emergencyMobileNumberDialingCode: formUpdateValues.emergencyMobileNumberDialingCode?.trim(),
                    emergencyMobileNumber: formUpdateValues.emergencyMobileNumber,
                    statusId: formUpdateValues.statusId ?? 0
                });
            }
            setReason('');
            setIsConfirmModalVisible(false);
        } catch (error) {
            message.error('Failed to save');
        }
    };

    const handleCancel = () => {
        setIsLoading(false);
        setIsVerifyModalVisible(false);
        setIsConfirmModalVisible(false);
    };

    const onUpdateUser = async (values: { travellerId: string, fullName: string, email: string, status: number, gender: string, languages: any, cities: any, address: string, country: number, state: number, city: number, emergencyName: string, emergencyMobileNoDialingCode: string, emergencyMobileNumber: string, isEmailVerified: boolean, isPhoneNoVerified: boolean, phoneNoCountryId: number }) => {
        let phoneNumber = form.getFieldValue("phoneNo");
        formUpdatedValues.current = { fullName: values.fullName, status: values.status, phonNoDialinCode: selectedCountry?.label[1], phoneNo: phoneNumber, email: values.email, gender: values.gender, country: values.country, state: values.state, city: values.city, address: values.address, isEmailVerified: values.isEmailVerified, isPhoneNoVerified: values.isPhoneNoVerified, countryDailingCode: countries.find(x => x.countryId === values.country)?.dialingCode ?? "", emergencyName: values.emergencyName, emergencyMobileNumber: values.emergencyMobileNumber };
        if (compareObjects(formInitialValues.current, formUpdatedValues.current)) {
            onClose();
        }
        else {
            setModalType('update');
            const param = {
                travellerId: values.travellerId,
                fullName: values.fullName ?? "",
                email: values.email ?? "",
                phoneNoDialingCode: (selectedCountry?.label[1] ?? selectedCountry?.label.props.children[1]) ?? "",
                phoneNoCountryId: values.phoneNoCountryId ?? 0,
                phoneNo: phoneNumber ?? "",
                imageUrl: profileImage ?? "",
                gender: values.gender ?? "",
                address: values.address,
                countryId: values.country ?? 0,
                stateId: values.state ?? 0,
                cityId: values.city ?? 0,
                emergencyName: values.emergencyName ?? "",
                emergencyMobileNumberDialingCode: (selectedEmergencyCode?.label[1] ?? selectedEmergencyCode?.label.props.children[1]) ?? "",
                emergencyMobileNumber: values.emergencyMobileNumber ?? "",
                statusId: values.status ?? 0
            };
            setFormUpdateValues(param);
            setIsConfirmModalVisible(true);
        }
    };

    const updateUserProfile = useCompleteUserProfile({
        onSuccess: (response: IResponse) => {
            setIsLoading(false);
            if (response.statusCode === 200) {
                message.success(response.statusMessage);
            } else {
                message.warning(response.statusMessage);
            }
            onClose();
            fetchTravellerList("", pageNumber.current, pageSize, searchValue.current ?? "");
        },
        onError: (error) => {
            setIsLoading(false);
            message.error(error.message);
        }
    });

    //#endregion confirmation modal operations

    //#region otp verify popup operations

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const openVerifyModal = (verificationType: string) => {
        isEmailType.current = (verificationType === "email");
        otpForm.setFieldValue("otpCheck", "");

        const { current: isEmail } = isEmailType;
        const email = form.getFieldValue("email");
        const phoneNo = form.getFieldValue("phoneNo");
        if ((email === "" && isEmail) || (email !== "" && isEmail && !isValidEmail(email))) {
            message.error("Please enter a valid email address");
            return;
        }
        if ((phoneNo === "" && !isEmail) || (phoneNo !== "" && !isEmail && !isValidPhoneNumber(phoneNo))) {
            message.error("Please enter a valid phone number");
            return;
        }
        let countryId = 0;
        if (parseInt(form.getFieldValue("country") ?? 0) > 0) {
            countryId = parseInt(form.getFieldValue("country"))
        }
        else if (isNotNullUndefined(selectedCountry)) {
            countryId = selectedCountry.value
        }
        form.setFieldValue("phoneNoDialingCode", countries.find(x => x.countryId === countryId)?.dialingCode ?? "");
        sendVerificationCodeMutation.mutate({
            userId: form.getFieldValue("travellerId") ?? "",
            userEmail: (verificationType === "email") ? email : "",
            phoneNumber: (verificationType === "sms") ? phoneNo : "",
            phoneNoDialingCode: countries.find(x => x.countryId === countryId)?.dialingCode ?? ""
        });
        codeType.current = verificationType;
    };

    const sendVerificationCodeMutation = useSendVerificationCode({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                setIsVerifyModalVisible(true);
                message.success("OTP sent successfully.");
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        }
    });

    const onVerify = () => {
        const code = otpForm.getFieldValue("otpCheck");
        if (isNotNullUndefinedBlank(code) && code.length === 6) {
            setModalLoading(true);
            verifiyVerificationCodeMutation.mutate({
                userEmail: form.getFieldValue("email"),
                phoneNumber: form.getFieldValue("phoneNo"),
                verificationCode: code,
                codeType: codeType.current
            });
        } else {
            message.warning("OTP must be 6 digits.");
        }
    };

    const onResend = () => {
        sendVerificationCodeMutation.mutate({ userEmail: form.getFieldValue("email"), phoneNumber: form.getFieldValue("phoneNo"), phoneNoDialingCode: countries.find(x => x.countryId === selectedCountry.value)?.dialingCode ?? "" });
        resetTimer();
    };

    const updateVerifiedStatusMutation = useUpdateVerifiedStatus({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                fetchTravellerList("", pageNumber.current, pageSize, "");
            } else {
                message.error(response.statusMessage);
            }
        }, onError: (error) => {
            message.error(error.statusMessage);
        }
    });

    const verifiyVerificationCodeMutation = useVerifyUserVerificationCode({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                updateUserStatus.mutate({ travellerId: form.getFieldValue("travellerId") ?? "", statusCode: "OTP_VERIFIED", reason: "" });
                message.success(response.statusMessage);
                let email = (form.getFieldValue("email") ?? "");
                let phoneNo = (form.getFieldValue("phoneNo") ?? "");
                if (!isEmailVerified) {
                    setEmailVerified((email !== "" && codeType.current === "email" && response.statusCode === 200));
                }
                if (!isSmsVerified) {
                    setSmsVerified((phoneNo !== "" && codeType.current === "sms" && response.statusCode === 200));
                }
                setModalLoading(false);
                setIsVerifyModalVisible(false);
            }
            else {
                setModalLoading(false);
                message.error(response.statusMessage);
                otpForm.setFieldValue("otpCheck", "");
            }
        },
        onError: (error) => {
            setModalLoading(false);
            message.error(error.message);
        }
    });

    const handleOtpChange = (event: any) => {
        const isNumberKey = !isNaN(event.key);
        const children = event.currentTarget.children;
        const targetIndex = Array.prototype.indexOf.call(children, event.target);
        let newValue = '';
        Array.prototype.forEach.call(children, (child, index) => {
            if (index === targetIndex) {
                newValue += event.key;
            } else {
                newValue += child.defaultValue;
            }
        });
        const isValidInput = !isNaN(Number(newValue));
        if (!isNumberKey || !isValidInput) {
            if (event.key.length === 1 && !event.ctrlKey && !event.altKey && !event.metaKey) {
                if (/[a-zA-Z\W]/.test(event.key)) {
                    message.error('OTP must only contain numbers.');
                    event.preventDefault();
                }
            }
            return false;
        }
    };
    //#endregion otp verify popup operations

    //#region pagination operation
    const handlePageChange = (newPage: number) => {
        pageNumber.current = newPage;
        const size = pageSize;
        setPageSize(size);
        fetchTravellerList("", newPage, pageSize, searchValue.current ?? "");
    };
    //#endregion pagination operation

    //#region update traveller status
    const updateUserStatus = useUpdateUserStatus({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                updateVerifiedStatusMutation.mutate({ userId: form.getFieldValue("travellerId") ?? "", codeType: codeType.current });
            }
            else {
                setModalLoading(false);
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            setModalLoading(false);
            message.error(error.message);
        }
    });
    //#endregion update traveller status

    return (
        <div>
            <div className="flex justify-between align-middle pb-5">
                <h2 className="text-2xl font-semibold text-black">Travellers</h2>
                <div className="w-80 max-w-80">
                    <Search
                        placeholder="Search user"
                        size="large"
                        enterButton
                        className="search-field"
                        onSearch={onSearch}
                    />
                </div>
            </div>

            <ConfigProvider
                theme={{
                    components: {
                        Tabs: {
                            titleFontSizeLG: 16,
                        },
                    }
                }}
            >
                <Tabs defaultActiveKey="1" onTabClick={(e) => { tabClicked(parseInt(e)) }}>
                    {
                        travellerStatuses.map((x: any) => (
                            <TabPane tab={`${x.statusName}`} key={`${x.statusId}`}>
                                <Table dataSource={travellerListData} className="userlist-tabs-table" bordered pagination={{ pageSize: pageSize, position: ['none'] }} locale={{
                                    emptyText: loading ? <Spin /> : (
                                        <div className="ant-empty">
                                            <div className="ant-empty-image">
                                                <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                                                    <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                                        <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                                        <g fillRule="nonzero" stroke="#d9d9d9">
                                                            <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                            <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="ant-empty-description">No data</div>
                                        </div>
                                    )
                                }}
                                >
                                    <Column title="User Name" dataIndex="fullName" key="fullName" align="center" />
                                    <Column title="Email" dataIndex="email" key="email" align="center" />
                                    <Column title="Mobile Number" dataIndex="phoneNo" key="phoneNo" align="center" />
                                    <Column title="Country" dataIndex="countryName" key="countryName" align="center" />
                                    {/* <Column title="State" dataIndex="stateName" key="stateName" align="center" /> */}
                                    <Column title="City" dataIndex="cityName" key="cityName" align="center" />
                                    <Column title="Gender" dataIndex="gender" key="gender" align="center" />
                                    <Column
                                        align="center"
                                        title="Status"
                                        dataIndex="statusName"
                                        key="statusName"
                                        render={(tags: string[]) => (
                                            <>
                                                {tags.map((tag) => (
                                                    <Tag key={tag} color={tag === "Cities Selected" ? "blue" : tag === "Interests Selected" ? "magenta" : tag === "Profile Updated" ? "yellow" : tag === "OTP Verified" ? "lime" : tag === "Deactivated" ? "red" : tag === "Draft" ? "cyan" : tag === "Deleted" ? "red" : "green"} className="m-0 pl-1 pr-1">
                                                        {tag.toUpperCase()}
                                                    </Tag>
                                                ))}
                                            </>
                                        )}
                                    />
                                    {x.statusId === 99 && (
                                        <Column title="Deleted Reason" dataIndex="reason" key="reason" align="center" className="word-wrap-column" />
                                    )}
                                    {x.statusId === 100 && (
                                        <Column title="Deactivated Reason" dataIndex="reason" key="reason" align="center" className="word-wrap-column" />
                                    )}
                                    <Column title="Created At" dataIndex="createdAt" key="createdAt" align="center" />
                                    <Column
                                        align="center"
                                        title="Action"
                                        key="action"
                                        render={(_: any, record: any) => (
                                            <Space size="middle">
                                                <Button onClick={() => showDrawer(record)} icon={<EditOutlined />} className="hover:!border-black hover:!text-black"></Button>
                                                {
                                                    (![99, 100].includes(record.statusId)) ?
                                                        <Button onClick={() => { onDeleteTraveller(record) }} icon={<DeleteOutlined />} className="hover:!border-black hover:!text-black"></Button>
                                                        : <></>
                                                }
                                            </Space>
                                        )}
                                    />
                                </Table>
                                <div>
                                    <Pagination
                                        currentPage={pageNumber.current}
                                        totalPages={totalPages}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </TabPane>
                        ))
                    }
                </Tabs>
                <Drawer title={'Traveller\'s Profile'} width={470} onClose={onClose} open={open} mask={false}>
                    <div>
                        <Form form={form} layout="vertical" onFinish={onUpdateUser}>
                            <Form.Item name="travellerId" hidden><Input /></Form.Item>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className="flex justify-start items-center mb-5">
                                        <div className="flex justify-start">
                                            <div className="w-[90px] h-[90px]">
                                                <img
                                                    src={profileImage}
                                                    className="rounded-full w-full h-full shadow-lg border-4 border-black object-cover"
                                                    alt="user"
                                                />
                                            </div>
                                            <div
                                                className="h-7 w-7 text-lg rounded-full bg-black relative flex items-center border-4 border-black justify-center top-[63px] right-[33px] transform origin-center cursor-pointer"
                                                onClick={handleEditClick}
                                            >
                                                <div className="w-3 h-3">
                                                    <img src={Images.imageedit} className="w-full h-full" alt="edit-img" />
                                                </div>
                                            </div>
                                            <input
                                                type="file"
                                                id="profileImageInput"
                                                accept="image/*"
                                                onChange={handleImageChange}
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                        <Form.Item
                                            name="fullName"
                                            label="Full Name"
                                            className="mb-0 w-full"
                                            rules={[{ required: true, message: "Please enter your full name" }]}>
                                            <Input className="h-12 w-full" placeholder="Full name" />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="status"
                                        label="Status"
                                        rules={[{ required: true, message: "Please Select Status" }]}
                                    >
                                        <Select
                                            className="h-12"
                                            placeholder="Please select Status"
                                            optionFilterProp="label"
                                            options={travellerStatusOptions.current ? travellerStatusOptions.current.map((x: any) => ({ label: x.statusName, value: x.statusId })) : []}
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="isPhoneNoVerified" hidden><Input /></Form.Item>
                                    <Form.Item name="phoneNoDialingCode" hidden><Input /></Form.Item>
                                    <Form.Item name="phoneNo" label="Phone Number"
                                        // rules={[
                                        //     ({ getFieldValue }) => ({
                                        //         validator(_, value) {
                                        //             const countryDialingCode = getFieldValue('phoneNoDialingCode') || selectedCountry?.value;

                                        //             if (value && !countryDialingCode) {
                                        //                 return Promise.reject(new Error('Please select your country code.'));
                                        //             }
                                        //             return Promise.resolve();
                                        //         },
                                        //     }),
                                        // ]}
                                        //rules={[{ required: true, message: "Please input your Phone number." }]}

                                        rules={[
                                            ({ getFieldValue }) => ({
                                                required: true,
                                                validator(_, value) {
                                                    const countryDialingCode = getFieldValue('phoneNoDialingCode') || selectedCountry?.value;

                                                    if (!value && !countryDialingCode) {
                                                        return Promise.reject(new Error('Please select your country code and enter your phone number.'));
                                                    }
                                                    if (value && !countryDialingCode) {
                                                        return Promise.reject(new Error('Please select your country code.'));
                                                    }
                                                    if (!value && countryDialingCode) {
                                                        return Promise.reject(new Error('Please enter your phone number.'));
                                                    }
                                                    if (value && (value.length < 7 || value.length > 12)) {
                                                        return Promise.reject(new Error("Phone number must be between 7 and 12 digits."));
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            addonBefore={
                                                <Select
                                                    labelInValue
                                                    value={{ value: selectedCountry?.value, label: selectedCountry?.label }}
                                                    className="h-12 w-[90px] country-code"
                                                    optionFilterProp="label"
                                                    onChange={(option) => handleDialingCodeChange(option, "phoneNo")}
                                                    options={countries ? countries.map(x => ({
                                                        label: (
                                                            <div className="flex items-center">
                                                                <img src={x.imageUrl} className='w-5 mr-2' alt="Country Flag" />
                                                                {x.dialingCode} {x.countryName}
                                                            </div>
                                                        ),
                                                        value: x.countryId
                                                    })) : []}
                                                    popupClassName="dropdown-max-content"
                                                    allowClear
                                                />
                                            }
                                            onChange={onChangePhoneInput} className="font-normal text-neutral-600 h-12 phone-input" placeholder="Enter your phone number"
                                            maxLength={12} />
                                    </Form.Item>
                                    <div>
                                        {isSmsVerified ? (
                                            <div>
                                                <img src={Images.righticon} alt="Right-icon" className='absolute top-[45px] right-5 w-[18px] z-10' />
                                            </div>
                                        ) : <></>}
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="isEmailVerified" hidden><Input /></Form.Item>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[{ required: true, message: "Please enter your email" }]}
                                    >
                                        <Input className="h-12" onChange={onChangeEmail} />
                                    </Form.Item>
                                    <div>
                                        {isEmailVerified ? (
                                            <div>
                                                <img src={Images.righticon} alt="Right-icon" className='absolute top-[45px] right-5 w-[18px] z-10' />
                                            </div>
                                        ) : <></>
                                        }
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="gender" label="Gender" rules={[{ required: true, message: "Please select gender." }]}>
                                        <Select className='font-medium text-neutral-600 h-12'
                                            placeholder="Select gender"
                                            onChange={onGenderChange}
                                            allowClear
                                        >
                                            <Option value="male">Male</Option>
                                            <Option value="female">Female</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="country"
                                        label="Country"
                                        rules={[{ required: true, message: "Please select your country" }]}
                                    >
                                        <Select
                                            showSearch
                                            className="font-medium text-neutral-600 h-12"
                                            placeholder="Please select a Country"
                                            optionFilterProp="label"
                                            options={countries ? countries.map(x => ({ label: x.countryName, value: x.countryId })) : []}
                                            onChange={() => fetchStateOptions(form.getFieldValue("country"))}
                                            allowClear
                                            defaultActiveFirstOption={false}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="state"
                                        label="State/Province"
                                        rules={[{ required: true, message: "Please select your state or province" }]}
                                    >
                                        <Select
                                            showSearch
                                            className="font-medium text-neutral-600 h-12"
                                            placeholder="Please select State/Province"
                                            optionFilterProp="label"
                                            options={stateOptions ? stateOptions.map(x => ({ label: x.stateName, value: x.stateId })) : []}
                                            onChange={() => fetchCityOptions(form.getFieldValue("state"), form.getFieldValue("country"))}
                                            allowClear
                                            defaultActiveFirstOption={false}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="city"
                                        label="City"
                                        rules={[{ required: true, message: "Please enter your city" }]}
                                    >
                                        <Select
                                            showSearch
                                            className="font-medium text-neutral-600 h-12"
                                            placeholder="Please select City"
                                            optionFilterProp="label"
                                            options={cityOptions ? cityOptions.map(x => ({ label: x.cityName, value: x.cityId })) : []}
                                            allowClear
                                            defaultActiveFirstOption={false}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="address"
                                        label="Address"
                                        rules={[{ required: true, message: "Please enter your address" }]}>
                                        <Input.TextArea
                                            className="!h-[150px]"
                                            style={{ resize: 'none', paddingTop: '10px' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Row>
                                    <Col span={24}>
                                        <h4 className="mb-2">Emergency Contact (optional)</h4>
                                    </Col>
                                </Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="emergencyName"
                                        label="Name"
                                    >
                                        <Input className="h-12" placeholder="Please enter Name" /></Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="emergencyDialingCode" hidden><Input /></Form.Item>
                                    <Form.Item name="emergencyMobileNumber" label="Mobile Number"
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    const countryDialingCode = getFieldValue('emergencyDialingCode') || selectedEmergencyCode?.value;

                                                    if (value && !countryDialingCode) {
                                                        return Promise.reject(new Error('Please select your country code.'));
                                                    }
                                                    else if (value && (value.length < 7 || value.length > 12)) {
                                                        return Promise.reject(new Error("Mobile number must be between 7 and 12 digits."));
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            addonBefore={
                                                <Select
                                                    labelInValue
                                                    value={{ value: selectedEmergencyCode?.value, label: selectedEmergencyCode?.label }}
                                                    className="h-12 w-[90px] country-code"
                                                    optionFilterProp="label"
                                                    onChange={(option) => handleDialingCodeChange(option, "emergencyNo")}
                                                    options={countries ? countries.map(x => ({
                                                        label: (
                                                            <div className="flex items-center">
                                                                <img src={x.imageUrl} className='w-5 mr-2' alt="Country Flag" />
                                                                {x.dialingCode} {x.countryName}
                                                            </div>
                                                        ),
                                                        value: x.countryId
                                                    })) : []}
                                                    popupClassName="dropdown-max-content"
                                                    allowClear
                                                />
                                            }
                                            onChange={onChangePhoneInput} className="font-normal text-neutral-600 h-12 phone-input" placeholder="Enter your phone number"
                                            maxLength={12} />
                                    </Form.Item>
                                </Col>
                                <div className="flex justify-end w-full gap-5">
                                    <div className="flex gap-2">
                                        <Button
                                            type="default"
                                            onClick={onClose}
                                            className="h-10 text-black border-black hover:bg-black hover:text-white hover:transition hover:duration-500 hover:ease-in-out"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            loading={loading}
                                            type="primary"
                                            htmlType="submit"
                                            onClick={() => form.submit()}
                                            className="h-10 bg-black text-white hover:!border-black hover:!bg-transparent hover:!text-black hover:transition hover:duration-500 hover:ease-in-out"
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </Row>
                        </Form>
                    </div>
                </Drawer>
                <Modal open={isVerifyModalVisible} footer={null} onCancel={handleCancel}>
                    <div className='flex justify-center items-center'>
                        <div className="p-6 w-full max-w-md">
                            <div>
                                <p className="text-center text-[32px] font-extrabold mb-6 text-black">Verify Your Authorization</p>
                                <p className='text-center text-base mb-5'>Enter the 6-digit verification code sent to
                                    {(isEmailType.current) ? (" " + form.getFieldValue("email") + " ") : (" " + form.getFieldValue("phoneNoDialingCode") + " " + form.getFieldValue("phoneNo") + " ")} to confirm your authorization.</p>
                                <Form form={otpForm} initialValues={{ otpCheck: "" }}>
                                    <Flex gap="middle" justify='center' className='otp-box' vertical>
                                        <Form.Item name="otpCheck">
                                            <Input.OTP onKeyDown={(event) => handleOtpChange(event)} />
                                        </Form.Item>
                                    </Flex>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className="h-14 w-full mt-6 text-xl bg-black text-white hover:!border-black hover:!bg-transparent hover:!text-black hover:transition hover:duration-500 hover:ease-in-out cursor-pointer"
                                            loading={modalLoading} onClick={onVerify}>
                                            Agreed & proceed
                                        </Button>
                                    </Form.Item>
                                    <div className="text-sm font-semibold text-center text-gray-500 mt-2 block">
                                        {resendTimer > 0 ? (
                                            <p className="font-semibold text-center text-gray-400 mt-2 block">
                                                Resend OTP in {resendTimer}s
                                            </p>
                                        ) : (
                                            <button onClick={onResend} type="button"
                                                className="font-semibold text-center text-gray-500 border-none">
                                                Resend OTP
                                            </button>
                                        )}
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    title={<span className="custom-modal-title">{getModalTitle()}</span>}
                    open={isConfirmModalVisible}
                    onOk={handleSave}
                    onCancel={handleCancel}
                    closeIcon={<CloseOutlined className="text-gray-500 hover:text-black" />}
                    footer={[
                        <Button key="back" onClick={handleCancel} className="hover:!text-black hover:!border-black">
                            Cancel
                        </Button>,
                        <Button key="submit" onClick={handleSave} className="bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black">
                            Confirm
                        </Button>,
                    ]}
                >
                    {getModelContent()}
                </Modal>
            </ConfigProvider>
        </div>
    );
}

export default Traveller;
